<template>
    <div>
        <div class="text-grey">Cargando... espere</div>
        <v-progress-linear
        indeterminate
        color="yellow darken-2"
        ></v-progress-linear>
        <br>
        <v-progress-linear
        indeterminate
        color="green"
        ></v-progress-linear>
        <br>
        <v-progress-linear
        indeterminate
        color="teal"
        ></v-progress-linear>
        <br>
        <v-progress-linear
        indeterminate
        color="cyan"
        ></v-progress-linear>
    </div>
</template>

<script>
    export default {
        name: 'Progress'
    }
</script>

<style lang="scss" scoped>

</style>