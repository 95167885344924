<template>
	<div>
		<download-excel :data="inscripciones" type="csv" :fields="json_fields"
			:name="fecha">
			<a>Descargar Listado Completo</a>
			<v-icon color="primary"> mdi-download</v-icon>
		</download-excel>
	</div>
</template>

<script>
	export default {
		name: 'DownloadInscripcionesComponent',
		props: {
			inscripciones: {
        type: Array,
        default: [],
      },
		},
		data: () => ({
			json_fields: {
				'EVENTO': 'evento.slug',
				'CONFIRMADO': 'confirmado',
				'CEDULA': 'user.cedula',
				'NOMBRE': 'user.nombre',
				'APELLIDO': 'user.apellido',
				'SEXO': 'user.sexo',
				'EMAIL': 'user.email',
				'TELEFONO': 'user.telefono',
				'CATEGORIA': 'user.categoria',
				'PATROCINANTE': 'sponsor.name',
				'MIEMBRO': 'user.miembro',
				'TRABAJO': 'user.trabajo',
				'ESPECIALIDAD': 'user.especialidad',
				'INSTITUCION': 'user.institucion',
				'VERIFICADO': 'user.verificado',
				'DIRECCION': 'user.direccion',
				'CIUDAD': 'user.ciudad',
				'ESTADO': 'user.estado',
				'PAIS': 'user.pais',
				'VAUCHER': 'vaucher',
				'CONSTANCIA': 'constancia',
				'CHECKIN': 'checkin',
				'NOTAS': 'notas'

			},
		}),
		computed: {
			fecha() {
				console.log(this.inscripciones[0].evento.slug)
				const year = new Date().getFullYear().toString()
				const month = (new Date().getMonth()+1).toString()
				const date = new Date().getDate().toString()
				const hour = new Date().getHours().toString()
				const minutes = new Date().getMinutes().toString()
				const hoy = `${this.inscripciones[0].evento.slug}-inscritos-${year}-${month}-${date}-${hour}-${minutes}.xls`
				return hoy
			},

		}
	}
</script>

<style lang="scss" scoped>

</style>