<template>
	<Layout>
		<v-container fluid>
			<v-row v-if="loading">
				<v-col cols="10">
					<progress-component />
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<h2 class="mb-4"> <v-icon large>mdi-file-document-multiple-outline</v-icon> ARCHIVO DE INSCRIPCIONES</h2>
						<v-card max-width="500" class="mx-auto">
							<v-select
								v-model="evento._id"
								:items="eventos"
								item-text="titulo"
								item-value="_id"
								prepend-icon="mdi-focus-field"
								menu-props="auto"
								hide-details
								label="Seleccione Evento"
							/>
						</v-card>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12">
					<list-component :event="evento" :key="evento._id" v-if="evento._id != ''" />
				</v-col>
			</v-row>
		</v-container>
	</Layout>
</template>

<script>
	import axios from 'axios';
  import store from '@/store'
	import ProgressComponent from '@/components/base/Progress.vue'
	import ListComponent from '../../components/inscripcion/ListInscripcionComponent.vue';
  const header = {"Token": store.state.userModule.token}
  const configuration = {headers: header}
	export default {
		name: 'InscripcionArchivo',
		components: {
			ProgressComponent,
			ListComponent
		},
		data: () => ({
			loading: false,
			eventos: [],
			eventoId: '',
			evento: {
				_id: '',
				titulo: '',
				subtitulo: '',
				slug: ''
			},
			dialog: false,
		}),
		mounted(){
			this.getEventos()
		},
		methods: {
			async getEventos(){
				this.loading = true
				try {
					const response = await axios.get('evento/list', configuration)
					this.eventos = response.data;
				} catch (e) {
					console.log(e.message)
				} finally {
					this.loading = false
				}
			},
			// async getEvento(){
			// 	try {
			// 		const response = await axios.get('evento/query-next', configuration)
			// 		this.evento = response.data.data;
			// 	} catch (e) {
			// 		console.log(e.message)
			// 	} finally {
			// 		this.loading = false
			// 	}
			// },
			createBread(){
				this.$store.dispatch('setBread',[{
					text: `Archivo de Inscripciones`,
					disabled: true,
					href: '#',
				}])
			},
		}, //methods

	}
</script>

<style lang="scss" scoped>

</style>