export default {

		bannerHeaderConfirmationEmail: "https://res.cloudinary.com/svdmqe-cloud/image/upload/v1619971721/ads/svd-default_gdqlmv.jpg",
		bannerHeaderConfirmationEmailLink: "https://eventos.svderma.org/",

		bannerHeaderSignup: "https://svderma.org/wp-content/uploads/2021/02/banner-phasilab2.gif",
		bannerHeaderSignupLink: "https://chemicaldampe.com/",

		bannerHeaderLogin: "https://svderma.org/wp-content/uploads/2021/02/banner-1-marcas-sofimedical.gif",
		bannerHeaderLoginLink: "https://sofimedical.com/",

		bannerHeaderSignupEmail: "https://svderma.org/wp-content/uploads/2021/02/banner-phasilab2.gif",
		bannerHeaderSignupEmailLink: "https://chemicaldampe.com/",

		bannerHeaderNextEvento: "https://svderma.org/wp-content/uploads/2021/02/Banner-EPIDUO-5.jpg",
		bannerHeaderNextEventoLink: "#",

		bannerHeaderInscripcion: "https://svderma.org/wp-content/uploads/2021/02/dermaskin.jpg",
		bannerHeaderInscripcionLink: "https://www.dermaskin.com.ve/",

		bannerHeaderInscripcionEmail: "https://svderma.org/wp-content/uploads/2021/02/banner-phasilab2.gif",
		bannerHeaderInscripcionEmailLink: "https://chemicaldampe.com/",

		bannerHeaderEventDetail: "https://svderma.org/wp-content/uploads/2021/02/banner-5-sofimedical.gif",
		bannerHeaderEventDetailLink: "https://sofimedical.com/",

		bannerCentralDashboard:  "https://svderma.org/wp-content/uploads/2021/02/tecno-banner-04-728x90-1.gif",
		bannerCentralDashboardLink:  "http://www.tecnovital.com.ve/dermatologia/inicio-derm.php",

		bannerTransmisionVivo: "https://svderma.org/wp-content/uploads/2021/02/UNIDERMA-CINTILLO.gif",
		bannerTransmisionVivoLink: "https://www.uniderma.info/",

		bannerTransmisionVivo2: "https://svderma.org/wp-content/uploads/2021/03/avpharma.gif",
		bannerTransmisionVivoLink2: "https://avpharma.com.ve/",

		bannerTransmisionDiferido: "https://svderma.org/wp-content/uploads/2021/03/avpharma.gif",
		bannerTransmisionVivoDiferido: "https://avpharma.com.ve/",

		bannerTransmisionDiferido2: "https://svderma.org/wp-content/uploads/2021/02/banner-phasilab2.gif",
		bannerTransmisionVivoDiferido2: "https://chemicaldampe.com/",
}