<template>
	<section>
		<v-card class="pa-2" flat>
			<v-card-actions>
				<v-row align="center">
					<h3 class="blue-grey--text mr-3" v-if="!loading">TOTAL INSCRITOS: {{this.inscripciones.length}}</h3>
				</v-row>
			</v-card-actions>
		</v-card>
		<v-card>
			<v-card-text>
				<v-data-table
					:headers="headers"
					:items="inscripciones"
					:search="search"
					item-key="_id"
					class="elevation-1"
					:loading="loading"
					loading-text="Cargando... Espere"
					no-data-text="Sin registros para mostrar"
					:footer-props="{
						'items-per-page-options': [10, 15, 20, 50]
					}"
					:items-per-page="20"
				>
					<template v-slot:top>
						<v-card class="d-flex justify-space-between" flat tile>
							<v-card flat tile>
								<v-text-field
									v-model="search"
									label="Buscar"
									class="mx-5"
								/>
							</v-card>
							<v-card flat tile>
								<v-btn class="grey--text" text @click.stop="getInscripciones">
									REFRESCAR
								</v-btn>
							</v-card>
							<v-card flat tile>
								<DownloadInscripciones :inscripciones="inscripciones"/>
							</v-card>
						</v-card>
					</template>

					<template v-slot:item.evento="{ item }">
						{{ item.evento.slug }}
					</template>
					<template v-slot:item.createdAt="{ item }">
						{{ dateFormat(item.createdAt) }}
					</template>
					<!-- template En Vivo -->
					<template v-slot:item.confirmado="{ item }">
						<v-layout justify-center>
							<v-chip
								class="ma-2 white--text"
								:color="item.confirmado ? 'success' : 'error' "
							>
								{{ item.confirmado ? 'SI' : 'NO' }}
							</v-chip>
<!--
							<v-switch
								color="success"
								v-model="item.confirmado"
								@change="toogleConfirmado(item)"
							></v-switch> -->

						</v-layout>
					</template>
					<!-- columna accion -->
					<template v-slot:item.action="{ item }">
						<v-icon  class="botones_list" color="primary"  @click.stop="detalleInscripcion(item)">
							mdi-file-eye-outline
						</v-icon>
					</template>

				</v-data-table>
				<DownloadInscripciones :inscripciones="inscripciones"/>
			</v-card-text>
		</v-card>
		<!-- Detalle de inscripcion -->
		<v-dialog
      v-model="dialog"
      max-width="600"
    >
      <v-card>
        <v-card-title class="headline">
					<h3>DETALLE INSCRIPCIÓN</h3>
        </v-card-title>
				<v-card-subtitle>
							Fecha: {{dateFormat(inscripcion.createdAt)}}
				</v-card-subtitle>

        <v-card-text>
					<v-row>
						<v-col cols="12" sm="6">
							<strong>Cédula: </strong>{{ user.cedula }}
							<br/><strong>Nombre: </strong> {{ user.nombre }}
							<br/><strong>Apellido: </strong> {{ user.apellido }}
							<br/><strong>Sexo: </strong> {{ user.sexo }}
							<br/><strong>Email: </strong> {{ user.email }}
							<br/><strong>Teléfono: </strong> {{ user.telefono }}
							<br/><strong>Miembro: </strong> {{ user.miembro == '' ? 'N/A' : user.miembro }}
							<br/><strong>Trabajo: </strong> {{ user.trabajo == '' ? 'N/A' : user.trabajo }}
						</v-col>
						<v-col cols="12" sm="6">
							<br/><strong>Especialidad: </strong> {{ user.especialidad == '' ? 'N/A' : user.especialidad }}
							<br/><strong>Institución: </strong> {{ user.institucion == '' ? 'N/A' : user.institucion }}
							<br/><strong>Dirección: </strong> {{ user.direccion }}
							<br/><strong>Ciudad: </strong> {{ user.ciudad }}
							<br/><strong>Estado: </strong> {{ user.estado }}
							<br/><strong>País: </strong> {{ user.pais }}
							<br/><strong>Verificado: </strong> {{ user.verificado ? 'SÍ' : 'NO '}}
						</v-col>
					</v-row>

					<section v-if="!event.gratis">
						<v-row>
							<v-col cols="12">
								<v-divider />
								<h3 class="text-center my-4">DETALLE DEL PAGO</h3>
								<v-divider />
							</v-col>
							<v-col cols="6">
								<strong>TIPO DE INSCRIPCIÓN</strong>
								<br/>{{inscripcion.tipo}}
								<br/><strong>Industria Patrociante</strong>
								<br/>{{inscripcion.sponsor}}
								<br/><strong>FORMA DE PAGO</strong>
								<br/>{{inscripcion.forma_pago}}
								<br/><strong>FECHA</strong>
								<br/>{{ inscripcion.fecha }}
							</v-col>

							<v-col cols="6">
								<strong>BANCO</strong>
								<br/>{{ inscripcion.banco }}
								<br/><strong>NUMERO</strong>
								<br/>{{ inscripcion.numero }}<br/>
								<p><strong>COMPROBANTE PAGO: </strong> <br/>

									<a v-if="urlVaucher(inscripcion.vaucher)" :href="inscripcion.vaucher" target="_blank">DESCARGAR</a>
									<span v-else>Sin Comprobante</span>

									<br/><strong>CONSTANCIA: </strong> <br/>
									<a v-if="urlVaucher(inscripcion.constancia)" :href="inscripcion.constancia" target="_blank">DESCARGAR</a>
									<span v-else>Sin Constancia</span>
									<br/><strong>NOTAS</strong>
									<br/>{{ inscripcion.notas }}

								</p>
							</v-col>
						</v-row>
					</section>


					<v-row>
						Al hacer clic en CONFIRMADO, el sistema enviará notificación al Participante
					</v-row>

        </v-card-text>

        <v-card-actions>
						<v-switch
							label="CONFIRMADO"
							color="success"
							v-model="inscripcion.confirmado"
							@change="toogleConfirmado({_id: inscripcion._id, confirmado: inscripcion.confirmado})"
						/>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            text
            @click.stop="borrarInscripcion(inscripcion._id)"
          >
            ELIMINAR
          </v-btn>
          <v-btn
            color="primary darken-1"
            text
            @click="closeDialog"
          >
            CERRAR
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
		<!-- .Detalle de inscripcion -->

	</section>
</template>

<script>
	import axios from 'axios';
  import store from '@/store'
	import Helpers from '@/services/Helpers.js'
  const header = {"Token": store.state.userModule.token}
  const configuration = {headers: header}
	import DownloadInscripciones from '@/components/inscripcion/Download.vue'
	import ads from '@/services/Ads'
	export default {
		name: 'ListComponent',
		components: {DownloadInscripciones},
		props: {
			event: {
				type: Object,
				required: true
			}
		},
		data: ()=> ({
			search: '',
			inscripciones:[],
			headers: [
				{ text: 'APELLIDO', align: 'start', value: 'user.apellido'},
				{ text: 'NOMBRE', align: 'start', value: 'user.nombre'},
				{ text: 'USUARIO', align: 'start', value: 'user.categoria'},
				{ text: 'EVENTO', align: 'start', value: 'evento'},
				{ text: 'CATEGORÍA', align: 'start', value: 'tipo'},
				{ text: 'PATROCINANTE', align: 'start', value: 'sponsor.name'},
				{ text: 'FECHA', value: 'createdAt'},
				{ text: 'CONFIRMADO', align: 'center', value: 'confirmado'},
				{ text: 'EN SITIO', align: 'center', value: 'checkin'},
				{ text: '', value: 'action', sortable: false, },
			],
			loading: true,
			dialog: false,
			user: {
				cedula: '',
				nombre: '',
				apellido: '',
				sexo: '',
				email: '',
				telefono: '',
				categoria: '',
				miembro: '',
				trabajo: '',
				especialidad: '',
				institucion: '',
				direccion: '',
				ciudad: '',
				estado: '',
				verificado: '',
			},
			inscripcion: {
				_id: '',
				tipo: '',
				forma_pago: '',
				banco: '',
				numero: '',
				fecha: '',
				vaucher: '#',
				constancia: '#',
				confirmado: false,
				createdAt: null,
				checkin: null,
				notas: ''
			},
		}),
		created(){
			this.getInscripciones()
		},
		methods: {
			async getInscripciones(){
				this.loading = true
				try {
					if (this.event._id != ''){
						const response = await axios.get(`inscripcion/list-by-event-id?evento=${this.event._id}` ,configuration)
						this.inscripciones = response.data.filter( x => x.user)
						console.log(this.inscripciones);
					}

				} catch (e) {
					console.log(e.message)
				} finally {
					this.loading = false
				}

			},
			dateFormat(date){
				return Helpers.dateFormat(date)
			},
			async toogleConfirmado(item){

				this.inscripcion.tipo = this.user.categoria
				this.event.bannerHeaderConfirmationEmail = ads.bannerHeaderConfirmationEmail
				this.event.bannerHeaderConfirmationEmailLink = ads.bannerHeaderConfirmationEmailLink
				try {
					const res = await await axios.put('inscripcion/toggle-confirmado',
							{
								'_id': item._id,
								'confirmado': item.confirmado,
								'evento': this.event,
								'inscripcion': this.inscripcion,
								'user': this.user
							},
							configuration)
							this.$store.commit('SET_ALERT_MESSAGE', {
								show: true,
								color: 'success',
								message: 'Inscripción Confirmada',
								timeout: 4000
							}, {root: true})
					this.getInscripciones()
				} catch (e) {
					console.log(e.message)
				} finally {
					this.closeDialog()
				}

			},
			detalleInscripcion(item) {
				this.user = item.user
				this.evento = item.evento
				this.inscripcion.confirmado = item.confirmado
				this.inscripcion.createdAt = item.createdAt
				this.inscripcion._id = item._id

				this.inscripcion.tipo = item.tipo != '' ? item.tipo : 'Sin Datos'
				this.inscripcion.forma_pago = item.forma_pago != '' ? item.forma_pago : 'Sin Datos'
				this.inscripcion.banco = item.banco != '' ? item.banco : 'Sin Datos'
				this.inscripcion.numero = item.numero != '' ? item.numero : 'Sin Datos'
				this.inscripcion.fecha = item.fecha != '' ? item.fecha : 'Sin Datos'
				this.inscripcion.vaucher = item.vaucher
				this.inscripcion.constancia = item.constancia
				this.inscripcion.sponsor = item.sponsor?.name ?? 'Ninguno'
				this.inscripcion.notas = item.sponsor?.notas ?? 'Ninguna'


				this.dialog = true
			},
			async borrarInscripcion(id){
				await this.$confirm('Seguro de borrar esta Inscripción?', {
					title: 'Warning',
					color: 'error',
					icon: 'mdi-hand',
					buttonTrueColor: 'primary',
					})
						.then(res => {
							if (res) {
								let me = this;
								axios.post('inscripcion/remove', {_id: id} ,configuration)
									.then(
										function (response) {
											const index = me.inscripciones.findIndex(item => item._id === id);
											me.inscripciones.splice(index, 1);
											me.$store.commit('SET_ALERT_MESSAGE', {
												show: true,
												color: 'success',
												message: 'Inscripción eliminada !!',
												timeout: 4000
											}, {root: true})
											me.dialog = false
										})
									.catch(function (e) {
										console.log(e.message)
									})
							}
						})
			},
			closeDialog(){
				this.user = {},
				this.evento = {}
				this.inscripcion.confirmado = null
				this.inscripcion.createdAt = ''
				this.inscripcion._id = ''
				this.dialog = false
			},
			urlVaucher(url) {
				return url != '' && url != '#'
			}
		},
		computed: {
		}

	}
</script>

<style lang="scss" scoped>

</style>